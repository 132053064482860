import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import getSheetData from "./getSheetData";

function App() {
	let [sheetData, setSheetData] = React.useState();
	let [selectedIndex, setSelectedIndex] = React.useState(0);

	React.useEffect(() => {
		getSheetData().then((sheetData) => {
			setSheetData(sheetData);

			for (let [index, { Date }] of sheetData.entries()) {
				let today = dayjs(),
					then = dayjs(Date);

				if (_.inRange(then.diff(today, "day"), 0, 6)) {
					setSelectedIndex(index);
					break;
				}
			}
		});
	}, []);

	if (!sheetData) return <p>Loading</p>;

	let selectedSunday = sheetData[selectedIndex];

	return (
		<React.Fragment>
			<nav>
				<button
					disabled={selectedIndex === 0}
					onClick={() => setSelectedIndex((index) => index - 1)}
				>
					←
				</button>
				<h1>{selectedSunday.Date}</h1>
				<button
					disabled={selectedIndex === sheetData.length - 1}
					onClick={() => setSelectedIndex((index) => index + 1)}
				>
					→
				</button>
			</nav>
			<table>
				<tbody>
					{Object.entries(selectedSunday)
						.slice(1)
						.map(([roleTitle, serverName], index) => (
							<tr>
								<td>{roleTitle}</td>
								<td>{serverName}</td>
							</tr>
						))}
				</tbody>
			</table>
		</React.Fragment>
	);
}

export default App;
