import { apiKey, sheetId } from "./googleInfo";

import { GoogleSpreadsheet } from "google-spreadsheet";

export default async () => {
	let doc = new GoogleSpreadsheet(sheetId, { apiKey });
	await doc.loadInfo(); // loads sheets
	let sheet = doc.sheetsByIndex[0]; // the first sheet
	let rows = await sheet.getRows();

	let rowsArray = [];

	for (let row of rows) {
		rowsArray.push(row.toObject());
	}

	return rowsArray;
};
